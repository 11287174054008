import { KEYBOARD_ESC_CODE } from '@collective/ui';
import { PictureBrokenComponent } from '@collective/ui/assets';
import { Box } from '@collective/ui/lib/layout/box';
import { ErrorIframe } from '@collective/ui-smart/lib/error-iframe/error-iframe';
import { useRouter } from 'next/router';
import { useKey } from 'react-use';

import { CaseStudyPublicPage } from '~/public-pages/components/pages/case-study-public-page';
import { useMarketplace } from '~/public-pages/contexts/marketplace-context';
import { PublicPageContextProvider } from '~/public-pages/contexts/public-page-context';
import { useGetCaseStudy } from '~/public-pages/hooks/marketplace/use-get-case-study';
import { useGetBuildMarketplaceUrl } from '~/public-pages/hooks/marketplace/use-get-marketplace-url';

import { PreviewSpinner } from './preview-spinner';

type PreviewCaseStudyModalPageProps = {
  slug: string;
  caseStudySlug: string;
};

export const PreviewCaseStudyModalPage = ({
  slug,
  caseStudySlug,
}: PreviewCaseStudyModalPageProps) => {
  const { initialCaseStudiesPagination } = useMarketplace();
  const { push } = useRouter();
  const buildUrl = useGetBuildMarketplaceUrl();
  const {
    data: { caseStudy } = {},
    isLoading,
    status,
    refetch,
  } = useGetCaseStudy(slug, caseStudySlug);

  const generatePublicPageLink = (collectiveSlug: string) => {
    return buildUrl({
      slug: collectiveSlug,
      caseStudySlug: null,
    });
  };

  useKey(
    KEYBOARD_ESC_CODE,
    () => {
      if (caseStudy) {
        push(generatePublicPageLink(caseStudy.collective.slug));
      }
    },
    undefined,
    [caseStudy]
  );

  if (isLoading) {
    return <PreviewSpinner observer={[slug, caseStudySlug]} />;
  }
  if (status === 'error') {
    return (
      <ErrorIframe onRefresh={() => refetch()}>
        <Box w="560px" h="400px" position="relative">
          <PictureBrokenComponent />
        </Box>
      </ErrorIframe>
    );
  }

  return (
    <Box overflowY="hidden" h="100%">
      <PublicPageContextProvider getPublicPageLink={generatePublicPageLink}>
        {caseStudy && initialCaseStudiesPagination && (
          <CaseStudyPublicPage
            caseStudy={caseStudy}
            caseStudiesPagination={initialCaseStudiesPagination}
          />
        )}
      </PublicPageContextProvider>
    </Box>
  );
};
