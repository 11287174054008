import { supportedLanguages } from '@collective/translation';
import { IconChevronDown } from '@collective/ui/lib/icon/icon';
import { Box, Flex } from '@collective/ui/lib/layout';
import {
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
} from '@collective/ui/lib/menu/menu';
import { Text } from '@collective/ui/lib/typography';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

type LanguageSwitcherProps = {
  generateRoute: (language: string) => LinkProps['href'];
  buttonProps?: MenuButtonProps;
};

export const LanguageSwitcher = ({
  generateRoute,
  buttonProps,
}: LanguageSwitcherProps) => {
  const { query } = useRouter();

  const currentLanguage = query.language;

  return (
    <Box ml={4}>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton color="rythm.700" {...buttonProps}>
              <Flex as="span" alignItems="center">
                <Text
                  variant="desktop-s-semibold"
                  color="inherit"
                  textTransform="uppercase"
                  w={5}
                >
                  {currentLanguage}
                </Text>
                <IconChevronDown
                  ml={1}
                  size="xs"
                  transition="0.3s ease"
                  transform={isOpen ? 'rotate(180deg)' : undefined}
                />
              </Flex>
            </MenuButton>

            <MenuList minW="0">
              {Object.keys(supportedLanguages)
                .sort()
                .map((language) => {
                  const active = language === currentLanguage;

                  return (
                    <Link key={language} href={generateRoute(language)}>
                      <MenuItem
                        color={active ? 'primary.600' : 'rythm.900'}
                        borderLeft="1px solid"
                        borderLeftColor={active ? 'primary.600' : 'transparent'}
                      >
                        {language.toUpperCase()}
                      </MenuItem>
                    </Link>
                  );
                })}
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};
