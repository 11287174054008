import {
  PublicPages_CaseStudyPagination,
  PublicPages_CollectiveFragment,
  PublicPages_GetCaseStudy,
} from '@collective/data-type';
import {
  IconArrowNarrowLeft,
  IconButton,
  IconChevronRight,
  Spacer,
  useBreakpointValue,
} from '@collective/ui';
import { Avatar } from '@collective/ui/lib/avatar';
import { Button } from '@collective/ui/lib/button';
import { Flex } from '@collective/ui/lib/layout';
import { Heading } from '@collective/ui/lib/typography';
import { PageTrackedKeys } from '@collective/utils/helpers';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'react-use';

import { ArrowsNavigation } from '~/public-pages/components/arrows-navigation/arrows-navigation';
import { ContactCollectiveButton } from '~/public-pages/components/contact/contact-collective-button';
import { useGetBuildMarketplaceUrl } from '~/public-pages/hooks/marketplace/use-get-marketplace-url';
import { useHasCollectiveSelection } from '~/public-pages/hooks/use-has-collective-selection';
import {
  formatUrl,
  toCollectiveCaseStudy,
  toCollectiveRoot,
} from '~/public-pages/router';
import { getIsMarketplacePage } from '~/public-pages/utils/urls';

type HeaderProps = {
  caseStudy: NonNullable<PublicPages_GetCaseStudy['caseStudy']>;
  caseStudiesPagination: PublicPages_CaseStudyPagination;
};

export const CaseStudyHeader = ({
  caseStudy,
  caseStudiesPagination,
}: HeaderProps) => {
  const { collective } = caseStudy;
  const buildUrl = useGetBuildMarketplaceUrl();
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const backLinkProps = {
    href: formatUrl(toCollectiveRoot(collective.slug)),
    passHref: true,
  };

  const { push } = useRouter();

  const onClose = () => push(toCollectiveRoot(collective.slug));

  const { pathname } = useRouter();
  const isMarketplacePage = getIsMarketplacePage(pathname);

  const previousUrl = isMarketplacePage
    ? buildUrl({
        caseStudySlug: caseStudiesPagination.previous,
        slug: caseStudy.collective.slug,
      })
    : toCollectiveCaseStudy(
        caseStudy.collective.slug,
        caseStudiesPagination.previous
      );

  const nextUrl = isMarketplacePage
    ? buildUrl({
        caseStudySlug: caseStudiesPagination.next,
        slug: caseStudy.collective.slug,
      })
    : toCollectiveCaseStudy(
        caseStudy.collective.slug,
        caseStudiesPagination.next
      );

  const onPrevious = () => push(previousUrl);
  const onNext = () => push(nextUrl);

  const handleKey = (code: string) => {
    switch (code) {
      case PageTrackedKeys.Escape:
        return onClose();
      case PageTrackedKeys.ArrowLeft:
        return onPrevious();
      case PageTrackedKeys.ArrowRight:
        return onNext();
    }
  };

  useEvent('keydown', (e: KeyboardEvent) => handleKey(e.code));

  return (
    <Flex
      align="center"
      px={{ base: '16px', md: '40px' }}
      py="12px"
      gap={4}
      borderBottom="1px solid"
      borderColor="rythm.300"
    >
      {isMobile ? (
        <CaseStudyHeaderMobile
          collective={collective}
          backLinkProps={backLinkProps}
        />
      ) : (
        <CaseStudyHeaderDesktop
          caseStudy={caseStudy}
          backLinkProps={backLinkProps}
          onPrevious={onPrevious}
          onNext={onNext}
        />
      )}
    </Flex>
  );
};

type CaseStudyHeaderMobileProps = {
  collective: PublicPages_CollectiveFragment;
  backLinkProps: LinkProps;
};

export const CaseStudyHeaderMobile = ({
  collective,
  backLinkProps,
}: CaseStudyHeaderMobileProps) => {
  return (
    <>
      <Link {...backLinkProps}>
        <Button
          variant="secondary"
          size="sm"
          leftIcon={<IconArrowNarrowLeft size="xs" color="rythm.600" />}
        >
          {collective.name}
        </Button>
      </Link>

      <Spacer />

      {collective.logoUrl && (
        <Avatar
          size="sm"
          src={collective.logoUrl}
          showBorder
          borderColor="primary.900"
        />
      )}
    </>
  );
};

type CaseStudyHeaderDesktopProps = {
  caseStudy: NonNullable<PublicPages_GetCaseStudy['caseStudy']>;
  backLinkProps: LinkProps;
  onNext: () => Promise<boolean>;
  onPrevious: () => Promise<boolean>;
};

export const CaseStudyHeaderDesktop = ({
  caseStudy,
  backLinkProps,
  onPrevious,
  onNext,
}: CaseStudyHeaderDesktopProps) => {
  const { t } = useTranslation();
  const hasCollectiveSelection = useHasCollectiveSelection();
  const { collective } = caseStudy;

  return (
    <>
      <Link {...backLinkProps}>
        <IconButton
          variant="iconButton"
          icon={<IconArrowNarrowLeft />}
          aria-label="Close"
        />
      </Link>
      <Flex gap={2} minW={0} align="center">
        <Heading
          variant="desktop-l-semibold"
          color="rythm.700"
          whiteSpace="nowrap"
          _hover={{ color: 'rythm.900' }}
        >
          <Link {...backLinkProps}>{collective.name}</Link>
        </Heading>
        <IconChevronRight size="xs" color="rythm.600" />
        <Heading
          variant="desktop-l-semibold"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {caseStudy.title}
        </Heading>
      </Flex>

      <Spacer />

      <ArrowsNavigation
        onPrevious={onPrevious}
        onNext={onNext}
        labelTranslationKey="marketplace.previewNavbar.useKeyboardNavigationCaseStudies"
      />

      {!hasCollectiveSelection && (
        <ContactCollectiveButton collectiveSlug={collective.slug}>
          {t('contact.button')}
        </ContactCollectiveButton>
      )}
    </>
  );
};
