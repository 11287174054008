import { PublicPages_MarketplaceCollectiveFragment } from '@collective/data-type';
import { PictureBrokenComponent } from '@collective/ui/assets';
import { Flex } from '@collective/ui/lib/layout';
import { Box } from '@collective/ui/lib/layout/box';
import { ErrorIframe } from '@collective/ui-smart/lib/error-iframe/error-iframe';
import { useRouter } from 'next/router';

import { CollectivePublicPage } from '~/public-pages/components/pages/collective-public-page';
import { PublicPageContextProvider } from '~/public-pages/contexts/public-page-context';
import { useGetCollective } from '~/public-pages/hooks/marketplace/use-get-collective';
import { useGetBuildMarketplaceUrl } from '~/public-pages/hooks/marketplace/use-get-marketplace-url';
import { getFirstItemIfArray } from '~/public-pages/utils/parsing';

import { PreviewModalHeader } from './preview-modal-header';
import { PreviewSpinner } from './preview-spinner';

type PreviewCollectiveModalPageProps = {
  collectives: PublicPages_MarketplaceCollectiveFragment[];
  index: number;
};

export const PreviewCollectiveModalPage = ({
  collectives,
  index,
}: PreviewCollectiveModalPageProps) => {
  return (
    <Flex h="100%" direction="column">
      <PreviewModalHeader collectives={collectives} index={index} />
      <PreviewCollectiveModalPageContent />
    </Flex>
  );
};

const PreviewCollectiveModalPageContent = () => {
  const {
    query: { slug: slugQuery },
  } = useRouter();
  const slug = getFirstItemIfArray(slugQuery ?? '');
  const buildUrl = useGetBuildMarketplaceUrl();
  const { data: { collective } = {}, status, refetch } = useGetCollective(slug);

  if (status === 'loading') {
    return <PreviewSpinner observer={[slug]} />;
  }
  if (status === 'error' || !collective) {
    return (
      <ErrorIframe onRefresh={() => refetch()}>
        <Box w="560px" h="400px" position="relative">
          <PictureBrokenComponent />
        </Box>
      </ErrorIframe>
    );
  }

  const generateCaseStudyLink = (slug: string, caseStudySlug: string) => {
    return buildUrl({
      slug,
      caseStudySlug,
    });
  };

  return (
    <Box overflowY="hidden" h="100%">
      <PublicPageContextProvider getCaseStudyPageLink={generateCaseStudyLink}>
        <CollectivePublicPage collective={collective} />
      </PublicPageContextProvider>
    </Box>
  );
};
