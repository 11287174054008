import {
  getCollectivePublicPageUrl,
  MARKETPLACE_SLUG,
} from '@collective/utils/helpers';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'react-i18next';

export const MarketplaceSeo = () => {
  const { asPath, query } = useRouter();
  const { t } = useTranslation();

  const { language } = query;

  const title = t('marketplace.seo.title');
  const description = t('marketplace.seo.description');
  const url = getCollectivePublicPageUrl(MARKETPLACE_SLUG);

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <NextSeo
        title={title}
        description={description}
        noindex
        nofollow
        openGraph={{
          url: `${url}${asPath}`,
          title,
          description,
          images: [
            {
              url:
                language === 'fr'
                  ? 'https://cdn.collective.work/clds4c2z510hb17vi5mzd195a.png'
                  : 'https://cdn.collective.work/clds4e2yl10i117vi97hd9kui.png',
              width: 2048,
              height: 1076,
            },
          ],
          site_name: title,
        }}
      />
    </>
  );
};
